<template>
  <v-card
   class="card-outter"
  >
    <v-img
      :lazy-src="path"
      :src="path"
      class="my-3"
      height="150"
    >
      <v-card
        class="card-inner subtitle-1 primary--text"
        :class="selected ? 'selected' :''"
        color="rgb(255, 255, 255, 0.9)"
      >
        {{ item.text }}
      </v-card>
      <v-fade-transition>
        <v-overlay
          v-if="item.overlay"
          absolute
          opacity="0.7"
          z-index="0"
        ></v-overlay>
      </v-fade-transition>
    </v-img>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import { choice as additiveChoice } from '@/utils/image/additiveTest';
import { choice as pathDependentChoice } from '@/utils/image/pathDependentTest';

export default {
  name: 'ChoiceImage',
  props: {
    item: Object,
  },
  computed: {
    ...mapGetters('testMacro', [
      'additive',
    ]),
    ...mapGetters('testMicro', [
      'id',
      'activeStep',
      'choiceJourney',
      'selectedIndex',
    ]),
    selected() {
      return this.selectedIndex === this.item.index;
    },
    path() {
      if (this.additive) {
        return additiveChoice(this.id, this.activeStep, this.item.index);
      }
      return pathDependentChoice(this.id, this.activeStep, this.item.index, this.choiceJourney);
    },
  },
};
</script>

<style scoped>
.card-outter {
  position: relative;
}

.card-inner {
  /* background: rgba(255, 255, 255, 0.85) !important; */
  position: absolute;
  bottom: 10%;
  border-radius: 0 !important;
  width: 100%;
  padding: 6px;
}

.selected {
  font-weight: bold;
}
</style>
