import { path } from '@/utils/image';

const choice = (testId, questionIndex, choiceIndex) => {
  const id = `${testId}_question${questionIndex}_choice${choiceIndex}`;
  return path(id);
};

export {
  choice,
};
