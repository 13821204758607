import { path } from '@/utils/image';
import { choice as additiveChoice } from '@/utils/image/additiveTest';

const choice = (testId, questionIndex, choiceIndex, choiceJourney) => {
  if (questionIndex === 0) {
    return additiveChoice(testId, questionIndex, choiceIndex);
  }
  // generate index string "0_1_3" for problems
  let choicePath = '';
  choiceJourney.forEach((idx) => {
    if (choicePath === '') {
      choicePath = `${idx}`;
    } else {
      choicePath = `${choicePath}_${idx}`;
    }
  });
  const id = `${testId}_question${questionIndex}_choice${choicePath}_${choiceIndex}`;
  return path(id);
};

export {
  choice,
};
